import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

export default function ServicesPage(props) {
  return (
    <Layout>
      <Seo title="Services" />
      <div class="contentBox">
        <h1>Services</h1>
      </div>
    </Layout>
  )
}
